import { RouteObject } from 'react-router-dom';

import { APP_ROUTES } from '../../routes';

export const SUMMARY_ROUTES: RouteObject = {
  path: APP_ROUTES.root,
  async lazy() {
    const { Summary } = await import('./summary');
    return {
      Component: () => <Summary />,
    };
  },
} as const;
