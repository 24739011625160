import { Outlet, RouteObject } from 'react-router-dom';

import { APP_ROUTES } from '../../routes';

export const MODULES_ROUTES: RouteObject = {
  path: APP_ROUTES.modules.main,
  element: <Outlet />,
  children: [
    {
      index: true,
      async lazy() {
        const { Modules } = await import('./main/modules');
        return {
          Component: () => <Modules />,
        };
      },
    },
    {
      path: APP_ROUTES.modules.create,
      async lazy() {
        const { CreateModule } = await import('./create/create-module');
        return {
          Component: () => <CreateModule />,
        };
      },
    },
    {
      path: APP_ROUTES.modules.edit,
      async lazy() {
        const { EditModule } = await import('./edit/edit-module');
        return {
          Component: () => <EditModule />,
        };
      },
    },
  ],
};
