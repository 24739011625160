import { RouteObject } from 'react-router-dom';

import { APP_ROUTES } from '../../routes';

export const NOT_FOUND_ROUTES: RouteObject = {
  path: APP_ROUTES.notFound,
  async lazy() {
    const { NotFound } = await import('./not-found');
    return {
      Component: () => <NotFound />,
    };
  },
} as const;
