import { HTTP_METHOD } from '@gbm/snacks-types';

import { config } from '../../config';
import request from '../utils/request';

export function createUserAPI(formData: {
  email: string;
  claims: { module: string; role: string }[];
}) {
  return request({
    url: `${config().CMS_API.v1}/users`,
    options: { method: HTTP_METHOD.post, body: formData },
  });
}

export function deleteUser(userEmail: string) {
  return request({
    url: `${config().CMS_API.v1}/users/${userEmail}`,
    options: { method: HTTP_METHOD.delete },
  });
}

export function updateUser(userData: {
  email: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  claims: {
    module: string;
    role: string;
  }[];
  createdAt?: string;
  modifiedAt?: string;
}) {
  return request({
    url: `${config().CMS_API.v1}/users/${userData.email}`,
    options: { method: HTTP_METHOD.put, body: userData },
  });
}

export function getUserData(userEmail: string) {
  return request({
    url: `${config().CMS_API.v1}/users/${userEmail}`,
    options: { method: HTTP_METHOD.get },
  });
}

export function getUsers() {
  return request({
    url: `${config().CMS_API.v1}/users`,
    options: { method: HTTP_METHOD.get },
  });
}
