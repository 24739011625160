import camelcaseKeys from 'camelcase-keys';
import { jwtDecode } from 'jwt-decode';
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { exchangeTokens } from '../../api/auth';
import { Loader } from '../../components/loader';
import { AuthTokensResponse } from '../../types/api/auth';
import {
  AUTH_BROADCASTER_ACTIONS,
  authBroadcaster,
  getTokens,
  handleLogin,
  handleLogout,
  isValidToken,
  notifyLogout,
  removeTokens,
  saveTokens,
} from '../../utils/auth';
import { createContext } from '../../utils/context';
import { AuthProviderValue, UserIdentity } from './types';

const [Provider, useProviderValue] = createContext<AuthProviderValue>();

export function AuthProvider({ children }: PropsWithChildren) {
  const userIdentity = useRef<UserIdentity>();
  const [authReady, setAuthReady] = useState(false);

  const saveSession = (userTokens: AuthTokensResponse) => {
    saveTokens(userTokens);

    setAuthReady(true);
  };

  const decodeUserIdentity = (idToken: string) => {
    const identity = camelcaseKeys(jwtDecode(idToken), {
      deep: true,
    }) as UserIdentity;
    userIdentity.current = identity;
  };

  const checkSession = useCallback(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlAuthCode = urlParams.get('code');

    if (urlAuthCode) {
      removeTokens();
      window.history.replaceState({}, '', window.location.pathname);

      try {
        const authResponse = await exchangeTokens({ code: urlAuthCode });
        decodeUserIdentity(authResponse.idToken);
        saveSession(authResponse);
      } catch {
        handleLogin();
      }
      return;
    }

    const authTokens = getTokens();

    if (!authTokens.accessToken || !isValidToken(authTokens.accessToken)) {
      handleLogin();
      return;
    }

    decodeUserIdentity(authTokens.idToken);
    setAuthReady(true);
  }, []);

  useEffect(() => {
    checkSession();
  }, [checkSession]);

  useEffect(() => {
    // Cross tab communication
    authBroadcaster.onmessage = (message) => {
      if ((message.data.action = AUTH_BROADCASTER_ACTIONS.logout)) {
        handleLogout();
      }
    };
  }, []);

  if (!authReady || !userIdentity.current) return <Loader />;

  return (
    <Provider
      value={{
        logout: () => {
          notifyLogout();
          handleLogout();
        },
        userIdentity: userIdentity.current,
      }}
    >
      {children}
    </Provider>
  );
}

export const useAuth = useProviderValue;
