import { HTTP_METHOD } from '@gbm/snacks-types';

import { config } from '../../config';
import { AuthTokensResponse } from '../types/api/auth';
import request from '../utils/request';

const { AUTH } = config();

export function exchangeTokens({ code }: { code: string }) {
  return request<AuthTokensResponse>({
    url: `${AUTH.API.v0}${AUTH.PATHS.TOKEN}`,
    options: {
      method: HTTP_METHOD.post,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: {
        client_id: AUTH.CLIENT_ID,
        code,
        grant_type: AUTH.GRANT_TYPES.EXCHANGE,
        redirect_uri: AUTH.LOGIN_REDIRECT_URL,
      },
    },
    auth: false,
  });
}

export function refreshToken({ refreshToken }: { refreshToken: string }) {
  return request<Omit<AuthTokensResponse, 'refreshToken'>>({
    url: `${AUTH.API.v0}${AUTH.PATHS.TOKEN}`,
    options: {
      method: HTTP_METHOD.post,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: {
        client_id: AUTH.CLIENT_ID,
        grant_type: AUTH.GRANT_TYPES.REFRESH,
        refresh_token: refreshToken,
      },
    },
    auth: false,
  });
}
