import { ENVIRONMENT, EnvironmentType } from '@gbm/snacks-types';

interface IENVIRONMENT {
  AUTH: {
    API: {
      v0: string;
    };
    CLIENT_ID: string;
    GRANT_TYPES: {
      EXCHANGE: string;
      REFRESH: string;
    };
    LOGIN_REDIRECT_URL: string;
    LOGIN_URL: string;
    PATHS: {
      TOKEN: string;
      AUTHORIZE: string;
      LOGOUT: string;
      LOGIN: string;
    };
    QUERY_PARAMS: {
      LOGIN: string;
    };
  };
  CMS_API: {
    v1: string;
  };
  LOCAL_STORAGE: {
    AUTH: string;
  };
}

const ENVIRONMENT_CONFIGURATION: IENVIRONMENT = {
  AUTH: {
    API: {
      v0: import.meta.env.VITE_AUTH_API_URL,
    },
    CLIENT_ID: import.meta.env.VITE_AUTH_CLIENT_ID,
    GRANT_TYPES: {
      EXCHANGE: 'authorization_code',
      REFRESH: 'refresh_token',
    },
    LOGIN_REDIRECT_URL: import.meta.env.VITE_LOGIN_REDIRECT_URL,
    LOGIN_URL: import.meta.env.VITE_LOGIN_URL,
    PATHS: {
      TOKEN: '/oauth2/token',
      AUTHORIZE: '/oauth2/authorize',
      LOGOUT: '/logout',
      LOGIN: '/login',
    },
    QUERY_PARAMS: {
      LOGIN:
        '&response_type=code&scope=openid+aws.cognito.signin.user.admin+profile&redirect_uri=',
    },
  },
  CMS_API: {
    v1: `${import.meta.env.VITE_AE_API_URL}/v1`,
  },
  LOCAL_STORAGE: {
    AUTH: 'gbm-session',
  },
};

export function config() {
  const appEnv = (import.meta.env.MODE ??
    ENVIRONMENT.development) as EnvironmentType;

  return {
    APP_ENV: appEnv,
    IS_DEV_ENV: appEnv === ENVIRONMENT.development,
    IS_STG_ENV: appEnv === ENVIRONMENT.stage,
    IS_PRD_ENV: appEnv === ENVIRONMENT.production,
    ...ENVIRONMENT_CONFIGURATION,
  };
}
