import { Outlet, RouteObject } from 'react-router-dom';

import { APP_ROUTES } from '../../routes';

export const ANNOUNCEMENTS_ROUTES: RouteObject = {
  path: APP_ROUTES.announcements.main,
  element: <Outlet />,
  children: [
    {
      index: true,
      async lazy() {
        const { Main } = await import('./main');
        return { Component: Main };
      },
    },
    {
      path: APP_ROUTES.announcements.edit,
      async lazy() {
        const { EditAnnouncements } = await import('./edit/editAnnouncements');
        return { Component: EditAnnouncements };
      },
    },
    {
      path: APP_ROUTES.announcements.create,
      async lazy() {
        const { CreateAnnouncement } = await import(
          './create/createAnnouncement'
        );
        return { Component: CreateAnnouncement };
      },
    },
  ],
};
