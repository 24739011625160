import isEmpty from 'just-is-empty';
import { PropsWithChildren, useEffect } from 'react';

import { useModules } from '../../services/modules/useModules';
import { useUser } from '../../services/users/useUser';

import { Loader } from '../../components/loader';

import { LOCAL_USER_NAMES } from '../../constants/local-user-names';
import { createContext } from '../../utils/context';
import { useAuth } from '../auth';
import { GrantsProviderValue } from './types';

const [Provider, useProviderValue] = createContext<GrantsProviderValue>();

/**
 * TODO: use a global state for generic data like `userData`
 */

export function GrantsProvider({ children }: PropsWithChildren) {
  const { logout, userIdentity } = useAuth();
  const { data: userData } = useUser(userIdentity.email);

  const { isError, isLoading } = useModules();

  useEffect(() => {
    if (isEmpty(userData)) return;
    localStorage.setItem(LOCAL_USER_NAMES.userData, JSON.stringify(userData));
  }, [userData]);

  if (isError) logout();

  if (isLoading || isEmpty(userData)) return <Loader />;

  return <Provider value={{}}>{children}</Provider>;
}

export const useGrants = useProviderValue;
