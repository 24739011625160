import { Outlet, RouteObject } from 'react-router-dom';

import { APP_ROUTES } from '../../routes';

export const USERS_ROUTES: RouteObject = {
  path: APP_ROUTES.users.main,
  element: <Outlet />,
  children: [
    {
      index: true,
      async lazy() {
        const { Users } = await import('./main/users');
        return {
          Component: () => <Users />,
        };
      },
    },
    {
      path: APP_ROUTES.users.create,
      async lazy() {
        const { CreateUser } = await import('./create/create-user');
        return {
          Component: () => <CreateUser />,
        };
      },
    },
    {
      path: APP_ROUTES.users.edit,
      async lazy() {
        const { EditUser } = await import('./edit/edit-user');
        return {
          Component: () => <EditUser />,
        };
      },
    },
  ],
};
