import { Outlet, RouteObject } from 'react-router-dom';

import { APP_ROUTES } from '../../routes';

export const REM_ROUTES: RouteObject = {
  path: APP_ROUTES.rules.main,
  element: <Outlet />,
  children: [
    {
      index: true,
      async lazy() {
        const { Main } = await import('./main');
        return { Component: Main };
      },
    },
    {
      path: APP_ROUTES.rules.create,
      async lazy() {
        const { Create } = await import('./create');
        return { Component: Create };
      },
    },
    {
      path: APP_ROUTES.rules.edit,
      async lazy() {
        const { Edit } = await import('./edit');
        return { Component: Edit };
      },
    },
  ],
};
