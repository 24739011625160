import { Outlet, RouteObject } from 'react-router-dom';

import { APP_ROUTES } from '../../routes';

export const CATEGORIES_ROUTES: RouteObject = {
  path: APP_ROUTES.categories.main,
  element: <Outlet />,
  children: [
    {
      index: true,
      async lazy() {
        const { Categories } = await import('./main/categories');
        return {
          Component: () => <Categories />,
        };
      },
    },
    {
      path: APP_ROUTES.categories.create,
      async lazy() {
        const { CreateCategory } = await import('./create/create-category');
        return {
          Component: () => <CreateCategory />,
        };
      },
    },
    {
      path: APP_ROUTES.categories.edit,
      async lazy() {
        const { EditCategory } = await import('./edit/edit-category');
        return {
          Component: () => <EditCategory />,
        };
      },
    },
  ],
};
