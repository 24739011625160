import announcementsFormEn from './en/components/announcements-form.json';
import fileButtonEn from './en/components/file-button.json';
import inactivityDetectorEs from './en/components/inactivity-detector.json';
import navigationEn from './en/components/navigation.json';
import radioGroupEn from './en/components/radio-group.json';
import tableEn from './en/components/table.json';
import announcementsEn from './en/containers/announcements.json';
import categoriesEn from './en/containers/categories.json';
import faqsEn from './en/containers/faqs.json';
import helpCenterEn from './en/containers/help-center.json';
import modulesEn from './en/containers/modules.json';
import notFoundEn from './en/containers/not-found.json';
import summaryEn from './en/containers/summary.json';
import usersEn from './en/containers/users.json';
import announcementsFormEs from './es/components/announcements-form.json';
import fileButtonEs from './es/components/file-button.json';
import inactivityDetectorEn from './es/components/inactivity-detector.json';
import navigationEs from './es/components/navigation.json';
import radioGroupEs from './es/components/radio-group.json';
import tableEs from './es/components/table.json';
import announcementsEs from './es/containers/announcements.json';
import categoriesEs from './es/containers/categories.json';
import faqsEs from './es/containers/faqs.json';
import helpCenterEs from './es/containers/help-center.json';
import modulesEs from './es/containers/modules.json';
import notFoundEs from './es/containers/not-found.json';
import summaryEs from './es/containers/summary.json';
import usersEs from './es/containers/users.json';

import { convertLanguageJsonToObject } from './translations';

export const DEFAULT_APP_LANG = 'es';
export const AVAILABLE_LANGUAGES = {
  es: 'es',
  en: 'en',
};

export const translationsJson = {
  es: {
    components: {
      navigation: navigationEs,
      announcementsForm: announcementsFormEs,
      table: tableEs,
      radioGroup: radioGroupEs,
      fileButton: fileButtonEs,
      inactivityDetector: inactivityDetectorEs,
    },
    containers: {
      announcements: announcementsEs,
      notFound: notFoundEs,
      summary: summaryEs,
      users: usersEs,
      modules: modulesEs,
      categories: categoriesEs,
      faqs: faqsEs,
      helpCenter: helpCenterEs,
    },
  },
  en: {
    components: {
      navigation: navigationEn,
      announcementsForm: announcementsFormEn,
      table: tableEn,
      radioGroup: radioGroupEn,
      fileButtonEn: fileButtonEn,
      inactivityDetector: inactivityDetectorEn,
    },
    containers: {
      announcements: announcementsEn,
      notFound: notFoundEn,
      summary: summaryEn,
      users: usersEn,
      modules: modulesEn,
      categories: categoriesEn,
      faqs: faqsEn,
      helpCenter: helpCenterEn,
    },
  },
};

convertLanguageJsonToObject(translationsJson.es);

export { translations } from './translations';
